










import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';

import GrLoading from '@/components/GrLoading.vue';
import { TrendResourceDto } from '@/repositories';

@Component({
  components: { GrLoading }
})
export default class CreateTrendFromTemplate extends Vue {
  @Prop()
  id!: string;

  @Action('templatesStore/createTrendFromTemplate') createTrendFromTemplate!: (id: string) => Promise<TrendResourceDto>;

  async created(): Promise<void> {
    const trend = await this.createTrendFromTemplate(this.id);
    // eslint-disable-next-line no-console
    if (!trend?.trend?.id) console.error('Error creating trend from template');
    else await this.$router.replace({ name: 'trendEditor', params: { id: trend?.trend?.id } });
  }
}
